.radial-chart-container {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.radial-chart-category-title {
  font-family: Rude !important;
  font-size: 20px;
  font-weight: normal;
}

@media (max-width: 426px) {
  .radial-chart-category-title {
    margin-top: 0.25em;
    margin-bottom: 0.75em !important;
  }
}

.radial-chart {
  width: 50% !important;
  height: 50% !important;
}

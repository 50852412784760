.shape {
  fill-opacity: 0;
}

.shape:hover {
  fill-opacity: 0.65;
  z-index: 100;
}

.scale {
  fill: #fff;
  stroke: #c7c8c7;
  stroke-width: 0.2;
}

.axis {
  stroke: #555;
  stroke-width: 0.2;
}

.dot {
  fill: #ed313c;
  stroke: #e7e8e7;
  r: 2;
  -webkit-transition: r 0.2s;
  -o-transition: r 0.2s;
  transition: r 0.2s;
}

.dot:hover {
  stroke: #bbb;
  r: 8;
}

.caption {
  fill: #000;
  font-family: Rude;
  font-size: 11px;
  font-weight: 600;
}

/*# sourceMappingURL=index.css.map*/

.results-page p.label {
  font-family: Rude !important;
  font-weight: 400;
  font-size: 18px;
}

.share-segment {
  background-color: #e6e7e7 !important;
}

.red-congrats {
  color: #ed2e38;
}

.line {
  width: 65%;
}

@media (max-width: 426px) {
  .line {
    width: 80%;
  }
}

.radial-chart-border {
  border-right: solid 0.5px #000000;
}

@media (max-width: 426px) {
  .radial-chart-border {
    border-right: none;
  }
}

.radial-chart-parent {
  padding-top: 10%;
  padding-bottom: 5%;
}

.category-description {
  font-family: Rude !important;
  font-weight: 500 !important;
  overflow-wrap: break-word;
  font-size: 25px;
}

@media (min-width: 426px) and (max-width: 769px) {
  .category-description {
    font-size: 18px;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  .category-description {
    font-size: 18px;
  }
}

.results-heading {
  height: 30px;
  font-family: Rude;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.3px;
  text-align: left;
  color: #000000;
}

.share-result-title {
  font-family: Rude !important;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #ed2e38 !important;
}

.social-media-icon {
  width: 37px;
  height: 37px;
  object-fit: contain;
}

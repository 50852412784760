@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

@font-face {
  font-family: "Rude";
  src: url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-Thin.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rude";
  src: url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-Light.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rude";
  src: url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rude";
  src: url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-Bold.ttf")
      format("truetype"),
    url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-Bold.otf")
      format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rude";
  src: url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-ExtraBold.ttf")
      format("truetype"),
    url("https://mirqah-scorecard.s3.us-east-2.amazonaws.com/assets/fonts/Rude-ExtraBold.otf")
      format("opentype");
  font-weight: 700;
  font-style: normal;
}

.App {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  b {
    font-family: "Montserrat", Helvetica, sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #151c32;
  }
  p {
    color: #303030;
    font-size: 1.2em;
  }
}
.black-text {
  color: #151c32;
}
.min-height {
  min-height: 100vh !important;
  font-weight: 500;
}

.blueBtn {
  color: #2f5bea !important;
  background-color: rgba(47, 91, 234.16, 0.14) !important;
  height: 69px;
}

.redBtn {
  height: 69px;
  color: white !important;
  background-color: #ee2447 !important;
  border-radius: 5px !important;
  box-shadow: 0 5px 20px 0 rgba(238, 36, 71, 0.2);
}

@media (max-width: 767px){
  .redBtn {
    font-size: 1rem !important;
  }
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}
.clearfix::after {
  clear: both;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-1 {
  padding-top: 0.25em !important;
}

.pl-1 {
  padding-left: 0.25em !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.25em !important;
}

.mt-2 {
  margin-top: 0.5em !important;
}

.mt-3 {
  margin-top: 1em !important;
}

.mb-1 {
  margin-bottom: 0.25em !important;
}

.mb-2 {
  margin-bottom: 0.5em !important;
}

.mb-3 {
  margin-bottom: 1em !important;
}

.my-3 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

@media (max-width: 767px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25em !important;
  }

  .mt-sm-2 {
    margin-top: 0.5em !important;
  }

  .mt-sm-3 {
    margin-top: 1em !important;
  }
  
  .mb-sm-3 {
    margin-bottom: 1em !important;
  }

  .my-sm-3 {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.w-75 {
  width: 75%
}

.w-100 {
  width: 100% !important;
}

.w-60 {
  width: 60% !important;
}

@media (max-width: 767px) {
  .w-sm-60 {
    width: 60% !important;
  }
}

/* mobile portrait */
@media (min-width: 427px) and (max-width: 767px) {
  .w-pr-40 {
    width: 40% !important;
  }
}

.d-none {
  display: none;
}

.d-sm-block {
  display: none !important;
}

.d-sm-none {
  display: block;
}

@media (max-width: 767px) {
  .d-sm-block {
    display: block!important;
  }

  .d-sm-none {
    display: none!important;
  }
}

.text-right {
  text-align: right;
}